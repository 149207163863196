import { useAsyncCallback } from '@orus.eu/pharaoh'
import { useNavigate, useParams } from '@tanstack/react-router'
import { useState, type ReactElement } from 'react'
import { trpc, trpcReact } from '../../../../client'
import { PendingSubscriptionsListContent } from '../common/backoffice-pending-subscriptions/pending-subscriptions-list-content'

export default function PartnersPendingSubscriptionsPage(): ReactElement {
  const { organization } = useParams({ from: '/partner/$organization/pending-subscriptions/' })
  const [creating, setCreating] = useState(false)
  const navigate = useNavigate()

  const { isLoading, data, error } =
    trpcReact.subscriptions.partnerListPendingSubscriptionsSummaries.useQuery(organization)
  const createSubscription = useAsyncCallback(async () => {
    setCreating(true)
    const subscriptionId = await trpc.subscriptions.partnerCreateSubscription.mutate({
      organizationTechnicalName: organization,
    })
    void navigate({
      to: '/partner/$organization/v2-pending-subscriptions/$subscriptionId',
      params: { organization, subscriptionId },
    })
  }, [navigate, organization])

  return (
    <PendingSubscriptionsListContent
      isCreating={creating}
      createSubscription={createSubscription}
      isSubscriptionListLoading={isLoading}
      hasSubscriptionListError={!!error}
      subscriptionList={data}
    />
  )
}
